<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="fumeextractor()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/soldering_rework/fume.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Fume Extractor
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="solderpot()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/soldering_rework/pot.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Solder Pot
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/soldering_rework/soldering">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/soldering_rework/soldering.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Soldering
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/soldering_rework/desoldering">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/soldering_rework/desoldering.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Desoldering
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/soldering_rework/convection_rework">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/soldering_rework/convection.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Convection Rework 
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="rework()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/soldering_rework/rework.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Rework 
              </h4>
            </div>
          </div>@click="rework()"
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/commingsoon">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:60%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/soldering_rework/robotsoldering.jpeg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Robotic Soldering
              </h4>
            </div>
          </div>
          </router-link>
        </div>
       
        <!-- end col -->
      </div>
    </div>
        <Footer />
    </div>
</template>
<script>

import Header from '../../components/Header.vue';

import Footer from '../../components/Footer.vue';

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
  
    Footer
    
   
  },
  methods: {
    fumeextractor(){
      window.location.href = "https://store.metcal.com/en-us/shop/fume-extraction/fume-extraction-systems/";
    },
     solderpot(){
      window.location.href = "http://www.waterun.com.cn/En/Products/index?cid=53";
    },
     rework(){
      window.location.href = "https://www.fonton.com/en/product-inner.php?id=117";
    },
  }
  
};
</script>